// Utilities
import { LogoIcons } from '../assets/svg';

const Intro = () => (
    <div className="sm:mx-1 pt-4">
        <div className="flex flex-row justify-center items-center">
            <LogoIcons />
        </div>
        <div className="font-bold text-3xl text-center font-sans text-white mt-8">
            Convert your Catalog CSV file to Buy with Prime format
        </div>
        <div className="font-normal text-base text-center font-sans text-white mt-4">
            Save time by converting your Shopify catalog file into Buy with Prime format using
            Catalog CSV file converter
        </div>
    </div>
);

export default Intro;
