// Core packages
import React, { useEffect, useState } from 'react';

// Custom components
import RequestRunning from './RequestRunning';

// Utilities
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useTransformFileMutation } from '../redux/service/api';
import { addDownloadUrl, changeStage, removeFile } from '../redux/slice/appSlice';
import { RequestType, Stage, TransformErrorType, OfferPrimeOptions } from '../types';

const Converting = () => {
    const [progress, setProgress] = useState(13);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useAppDispatch();
    const { shopUrl, transform, skuValue, offerPrime, productCatalog } = useAppSelector(
        (state) => state.appSlice,
    );

    const [
        triggerTransform,
        {
            data: downloadData,
            isSuccess: isSuccessTransform,
            isError: isErrorTransform,
            isLoading,
            error,
        },
    ] = useTransformFileMutation();

    useEffect(() => {
        const payload = {
            source: 'shopify',
            product_catalog: productCatalog,
            fields: {
                shop_url: {
                    value: shopUrl,
                },
                sku: {
                    transform,
                    value: skuValue,
                },
                offer_prime: {
                    value: offerPrime === OfferPrimeOptions.YES,
                },
            },
        };
        if (!isLoading) triggerTransform(payload);
    }, []);

    useEffect(() => {
        if (isSuccessTransform) {
            setProgress(100);
            dispatch(addDownloadUrl(downloadData?.download_url));
            dispatch(changeStage(Stage.DOWNLOAD));
        }
    }, [isSuccessTransform]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev <= 95) return prev + 3;
                return prev;
            });
        }, 600);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (error) {
            if ('data' in error) {
                const msg = (error as TransformErrorType).data.detail;
                console.log('message : ', msg);
                setErrorMessage(msg);
            }
        }
    }, [error]);

    const uploadNew = () => {
        dispatch(removeFile());
        dispatch(changeStage(Stage.SELECT));
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full py-10 px-6 md:px-10">
            <RequestRunning
                isError={isErrorTransform}
                progress={progress}
                type={RequestType.CONVERSION}
                errorMessage={errorMessage}
            />
            {isErrorTransform ? (
                <div className="flex flex-row w-full my-6">
                    <button
                        type="button"
                        className="button-choose-csv w-full font-sans text-sm font-medium text-white rounded"
                        onClick={uploadNew}
                    >
                        Upload new
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default React.memo(Converting);
