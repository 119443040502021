/* eslint-disable no-unused-vars */
// Core packages
import { handler } from '@material-tailwind/react/types/components/dialog';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';

export enum OfferPrimeOptions {
    YES = 'YES',
    NO = 'NO',
    NOT_SELECTED = 'NOT_SELECTED',
}

export enum Transform {
    SAME = 'same',
    PREFIX = 'prefix',
    SUFFIX = 'suffix',
    MANUAL = 'manual',
}

export enum Stage {
    SELECT = 'select',
    UPLOADING = 'uploading',
    CONVERT = 'convert',
    CONVERTING = 'converting',
    DOWNLOAD = 'download',
}

export enum RequestType {
    UPLOAD_FILE = 'upload_file',
    CONVERSION = 'conversion',
}

export interface AppState {
    stage: Stage;
    file: string;
    fileName: string;
    fileSize: number;
    shopUrl: string;
    transform: Transform;
    skuValue: string;
    productCatalog: string;
    downloadUrl: string;
    offerPrime: OfferPrimeOptions;
}

export interface SKUInputProps {
    transform: Transform;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    title: string;
    showInput?: boolean;
    skuValue?: string;
    onChangeValue?: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    tooltip: string;
}

export interface OfferPrimeProps {
    value: OfferPrimeOptions;
    origin: OfferPrimeOptions;
    onChange: ChangeEventHandler<HTMLInputElement>;
    title: string;
    tooltip: string;
}

export interface UploadUrlResponse {
    url: string;
    product_catalog: string;
}

export interface DownloadUrlResponse {
    download_url: string;
}

export interface RequestRunningType {
    isError: boolean;
    progress: number;
    type: RequestType;
    errorMessage?: string;
}

export interface InstructionModalProps {
    showInstruction: boolean;
    toggleModal: Dispatch<SetStateAction<boolean>>;
}

export interface SliderDataItems {
    id: number;
    title: string;
    description: string;
}

export interface TransformErrorType {
    data: {
        detail: string;
    };
}

export interface ModalProps {
    showModal: boolean;
    toggleModal: handler;
    submit?: handler;
    loading?: boolean;
}
