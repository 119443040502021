// Core packages
import { useEffect, useState } from 'react';

// Custom components
import axios from 'axios';
import RequestRunning from './RequestRunning';

// Utilities
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useLazyGetUploadUrlQuery } from '../redux/service/api';
import { addProductCatalog, changeStage, removeFile } from '../redux/slice/appSlice';
import { RequestType, Stage } from '../types';

const Uploading = () => {
    const [progress, setProgress] = useState(6);
    const [isError, setIsError] = useState(false);
    const dispatch = useAppDispatch();
    const { file } = useAppSelector((state) => state.appSlice);

    const [
        triggerGetUploadUrl,
        { isSuccess: isSuccessFetchUrl, data: urlData, isError: isErrorFetchUrl },
    ] = useLazyGetUploadUrlQuery();

    const uploadFile = (input: string) => {
        const config = {
            onUploadProgress(progressEvent: any) {
                const percentCompleted =
                    Math.round((progressEvent.loaded * 80) / progressEvent.total) + 20;
                setProgress(percentCompleted);
            },
        };

        axios
            .put(urlData?.url ?? '', input, config)
            .then(() => {
                dispatch(changeStage(Stage.CONVERT));
            })
            .catch((err) => {
                console.log(err);
                setIsError(true);
            });
    };

    useEffect(() => {
        if (isErrorFetchUrl) setIsError(true);
    }, [isErrorFetchUrl]);

    useEffect(() => {
        triggerGetUploadUrl();
    }, []);

    useEffect(() => {
        if (isSuccessFetchUrl) {
            setProgress(20);
            dispatch(addProductCatalog(urlData?.product_catalog));
            uploadFile(file);
        }
    }, [isSuccessFetchUrl]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev <= 17) return prev + 3;
                return prev;
            });
        }, 600);
        return () => clearInterval(interval);
    }, []);

    const retry = () => {
        dispatch(removeFile());
        dispatch(changeStage(Stage.SELECT));
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-full py-10 px-6 md:px-10">
            <RequestRunning isError={isError} progress={progress} type={RequestType.UPLOAD_FILE} />
            {isError ? (
                <div className="flex flex-row justify-center w-full my-6">
                    <button
                        type="button"
                        className="button-choose-csv w-full font-sans text-sm font-medium text-white rounded mx-2"
                        onClick={retry}
                    >
                        Retry
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default Uploading;
