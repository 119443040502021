// Custom Components
import Intro from './components/Intro';
import UploadBox from './components/UploadBox';
import ContactUs from './components/ContactUs';
import HowItWorks from './components/HowItWorks';

// Styles
import './App.css';

function App() {
    return (
        <div className="app app-background grid grid-cols-1 md:grid-cols-2 py-12 md:py-0">
            <div className="w-full h-full flex justify-center items-center">
                <div className="flex flex-col w-full sm:w-3/4 lg:w-1/2 mx-10 sm:mx-0 h-full justify-center items-center">
                    <Intro />
                    <div className="hidden md:flex flex-col justify-center items-center w-full">
                        <HowItWorks />
                        <ContactUs />
                    </div>
                </div>
            </div>
            <div className="flex items-center mx-4 md:mx-0 mt-8 md:mt-0 h-full">
                <div className="flex flex-col justify-center items-center w-full md:w-4/5 2xl:w-2/3 h-full">
                    <UploadBox />
                    <div className="flex md:hidden flex-col justify-center items-center w-full mb-20">
                        <HowItWorks />
                        <ContactUs />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
