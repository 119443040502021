// Utilities
import { SliderDataItems } from '../types';

export const WORKFLOW = [
    'Download your product catalog CSV file from Shopify',
    'and download your file here',
    'Go back to your Buy with Prime product catalog',
    'Choose',
];
export const UPLOAD_YOUR_FILE = 'Upload your file';
export const UPLOADING_FILE_TITLE = 'Uploading your file';
export const UPLOADING_FILE_DESCRIPTION = 'Do not close or refresh your browser.';
export const UPLOADING_FAILED_TITLE = 'Uploading your file was unsuccessful.';
export const UPLOADING_FAILED_DESCRIPTION = 'Please try again or upload a new file.';
export const CONVERT_YOUR_FILE = 'Convert your file';
export const DOWNLOAD_YOUR_FILE = 'Download your file';
export const CHOOSE_CSV_FILE = 'Choose CSV file';
export const CHOOSE_ANOTHER_CSV_FILE = 'Choose another CSV file';
export const FILE_NOT_SUPPORTED = 'File format is not supported.';
export const FILE_SIZE_EXCEEDED = 'File exceeds 20MB size limit.';
export const CONVERTING_TITLE = 'Your file is being converted.';
export const CONVERTING_FAILED_TITLE = 'Converting your file was unsuccessful.';
export const CONVERTING_DESCRIPTION = 'Do not close or refresh your browser.';
export const CONVERTING_FAILED_DESCRIPTION = 'and try again';
export const sliderData: SliderDataItems[] = [
    {
        id: 0,
        title: "In your Shopify store's admin panel choose Products",
        description:
            "Login into your store's admin panel. In the left menu click Products. Page with your product catalog wil open.",
    },
    {
        id: 1,
        title: "Click 'Export' on the top menu",
        description: "In the top menu click on 'Export'. A popup with export settings will open.",
    },
    {
        id: 2,
        title: "Choose 'All products' and (important!) 'Plain CSV file'",
        description:
            "Decide which products you want to export. It is very important, under 'Export as:' to choose 'Plain CSV file', other formats cannot be converted using this tool.",
    },
    {
        id: 3,
        title: "Click 'Export products' button and wait for email from Shopify with CSV file",
        description:
            "After 'Export products' button is clicked you will receive an email from Shopify with the link to CSV file that you will later upload to this tool.",
    },
];
export const INVALID_CSV_API_ERROR = 'Invalid CSV.';
export const INVALID_CSV_ERROR_MESSAGE =
    'Uploaded file is not formatted correctly. You can download the correct CSV file following';
export const TERMS_SERVICES_URL = 'https://sixads.net/catalog-converter-terms-and-conditions/';
export const PRIVACY_POLICY_URL = 'https://sixads.net/16871-2/';
