// Core packages
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Custom components
import DropboxLayer from './DropboxLayer';
import Uploading from './Uploading';

// Utilities
import { AddCSVIcon, ErrorCSVIcon } from '../assets/svg';
import { addFile, changeStage } from '../redux/slice/appSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
    CHOOSE_ANOTHER_CSV_FILE,
    CHOOSE_CSV_FILE,
    FILE_NOT_SUPPORTED,
    FILE_SIZE_EXCEEDED,
} from '../constants';
import { Stage } from '../types';

const DropBox = () => {
    const dispatch = useAppDispatch();
    const { stage, file } = useAppSelector((state) => state.appSlice);
    const [error, setError] = useState('');

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
        noClick: true,
        multiple: false,
        maxFiles: 1,
    });

    useEffect(() => {
        if (acceptedFiles.length) {
            const isFileTypeNotValid =
                acceptedFiles[0].type !== 'text/csv' &&
                acceptedFiles[0].type !== 'application/vnd.ms-excel';
            const isFileSizeTooLarge = acceptedFiles[0].size / 1000000 > 20;

            if (isFileTypeNotValid) setError(FILE_NOT_SUPPORTED);
            else if (isFileSizeTooLarge) setError(FILE_SIZE_EXCEEDED);
            else {
                const reader = new FileReader();
                reader.readAsText(acceptedFiles[0]);
                reader.onload = function () {
                    setError('');
                    dispatch(
                        addFile({
                            file: reader.result,
                            fileName: acceptedFiles[0].name,
                            fileSize: acceptedFiles[0].size,
                        }),
                    );
                    dispatch(changeStage(Stage.UPLOADING));
                };
            }
        }
    }, [acceptedFiles]);

    const Error = (
        <div className="flex flex-col justify-center items-center mt-6 mx-4">
            <div className="error font-sans font-normal text-sm text-center">{error}</div>
            <div className="try-again font-sans font-normal text-xs leading-5 text-center">
                Please try again.
            </div>
        </div>
    );

    return (
        <div
            {...getRootProps()}
            className="drop-zone relative flex flex-col justify-center items-center rounded w-full h-full my-8 py-6"
        >
            {stage === Stage.SELECT ? (
                <>
                    <input {...getInputProps()} />
                    {!file ? <AddCSVIcon /> : error ? <ErrorCSVIcon /> : null}
                    {acceptedFiles.length && error ? Error : null}
                    <button
                        type="button"
                        className={`button-choose-csv w-3/4 font-sans text-sm font-medium text-white rounded mt-6 mb-4 ${
                            isDragActive ? 'opacity-20' : ''
                        }`}
                        onClick={open}
                    >
                        {!file ? CHOOSE_CSV_FILE : error ? CHOOSE_ANOTHER_CSV_FILE : ''}
                    </button>
                    <div
                        className={`drop-here font-sans text-center ${
                            isDragActive ? 'opacity-20' : ''
                        }`}
                    >
                        or drop it here
                    </div>
                    {isDragActive ? <DropboxLayer /> : null}
                </>
            ) : null}
            {stage === Stage.UPLOADING ? <Uploading /> : null}
        </div>
    );
};

export default DropBox;
