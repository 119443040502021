/* eslint-disable jsx-a11y/no-static-element-interactions */
// Core packages
import React, { useEffect, useState } from 'react';

// Custom packages
import DropBox from './DropBox';
import ConvertFile from './ConvertFile';
import Converting from './Converting';
import Download from './Download';

// Utilities
import { useAppSelector } from '../redux/hooks';
import {
    CONVERT_YOUR_FILE,
    DOWNLOAD_YOUR_FILE,
    UPLOADING_FILE_TITLE,
    UPLOAD_YOUR_FILE,
    TERMS_SERVICES_URL,
    PRIVACY_POLICY_URL,
} from '../constants';
import { Stage } from '../types';

const UploadBox = () => {
    const { stage } = useAppSelector((state) => state.appSlice);
    const [conditionalHeight, setConditionalHeight] = useState('');

    const openTerms = () => {
        window.open(TERMS_SERVICES_URL, '_blank');
    };

    const openPrivacy = () => {
        window.open(PRIVACY_POLICY_URL, '_blank');
    };

    useEffect(() => {
        if (
            stage === Stage.SELECT ||
            stage === Stage.UPLOADING ||
            stage === Stage.CONVERTING ||
            stage === Stage.DOWNLOAD
        ) {
            setConditionalHeight('h-5/6');
        } else if (stage === Stage.CONVERT) {
            setConditionalHeight('h-max');
        }
    }, [stage]);

    const renderElement = () =>
        stage === Stage.SELECT || stage === Stage.UPLOADING ? (
            <DropBox />
        ) : stage === Stage.CONVERT ? (
            <ConvertFile />
        ) : stage === Stage.CONVERTING ? (
            <Converting />
        ) : stage === Stage.DOWNLOAD ? (
            <Download />
        ) : null;

    return (
        <div
            className={`flex flex-col ${conditionalHeight} min-h-[600px] px-6 py-8 justify-center items-center bg-white rounded`}
        >
            <div className="upload-file font-sans text-2xl font-bold text-center">
                {stage === Stage.SELECT
                    ? UPLOAD_YOUR_FILE
                    : stage === Stage.UPLOADING
                    ? UPLOADING_FILE_TITLE
                    : stage === Stage.CONVERT
                    ? CONVERT_YOUR_FILE
                    : stage === Stage.CONVERTING
                    ? CONVERT_YOUR_FILE
                    : stage === Stage.DOWNLOAD
                    ? DOWNLOAD_YOUR_FILE
                    : null}
            </div>
            {renderElement()}
            <p className="upload-file font-sans text-xs font-normal text-center mx-6">
                By using Catalog CSV file converter you agree to Six Ads{' '}
                <span className="privacy-policy cursor-pointer" onClick={openTerms}>
                    terms and conditions
                </span>{' '}
                and{' '}
                <span className="privacy-policy cursor-pointer" onClick={openPrivacy}>
                    privacy policy
                </span>
                .
            </p>
        </div>
    );
};
export default React.memo(UploadBox);
