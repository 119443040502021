/* eslint-disable jsx-a11y/interactive-supports-focus */
// Core Packages
import { useState } from 'react';

// Custom components
import InstructionModal from './InstructionModal';

// Utilities
import { WORKFLOW } from '../constants';

const HowItWorks = () => {
    const [showInstruction, setShowInstruction] = useState(false);

    const toggleModal = () => setShowInstruction((prev) => !prev);

    return (
        <>
            <div className="how-it-works w-full flex flex-col p-6 mt-12 rounded-t">
                <div className="font-bold text-lg text-white font-sans">How it works</div>
                <div className="w-full h-px bg-white opacity-10 my-4" />
                <div className="flex flex-col">
                    <div className="flex flex-row mb-2">
                        <div className="how-it-works-index bg-white upload-file font-sans font-normal text-sm w-4 h-4 p-2.5 mr-3 ml-1 rounded-full flex justify-center items-center  mt-1">
                            1
                        </div>
                        <div>
                            <span className="font-sans font-normal text-sm text-white items-center">
                                {WORKFLOW[0]}
                            </span>
                            <span
                                className="cursor-pointer font-sans font-normal text-sm text-lime-600 pl-1"
                                role="button"
                                onClick={() => setShowInstruction(true)}
                            >
                                (see how)
                            </span>
                            <span className="font-sans font-normal text-sm text-white items-center pl-1">
                                and upload here
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-row mb-2">
                        <div className="how-it-works-index bg-white font-sans font-normal text-sm w-4 h-4 p-2.5 mr-3 ml-1 rounded-full flex justify-center items-center  mt-1">
                            2
                        </div>
                        <div>
                            <span className="font-sans font-semibold text-sm text-white items-center">
                                Convert
                            </span>
                            <span className="font-sans font-normal text-sm text-white items-center pl-1">
                                {WORKFLOW[1]}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-row mb-2">
                        <div className="how-it-works-index bg-white font-sans font-normal text-sm w-4 h-4 p-2.5 mr-3 ml-1 rounded-full flex justify-center items-center  mt-1">
                            3
                        </div>
                        <div className="font-sans font-normal text-sm text-white items-center">
                            {WORKFLOW[2]}
                        </div>
                    </div>
                    <div className="flex flex-row mb-2">
                        <div className="how-it-works-index bg-white font-sans font-normal text-sm w-4 h-4 p-2.5 mr-3 ml-1 rounded-full flex justify-center items-center  mt-1">
                            4
                        </div>
                        <div>
                            <span className="font-sans font-normal text-sm text-white items-center">
                                {WORKFLOW[3]}
                            </span>
                            <span className="font-sans font-semibold text-sm text-white items-center pl-1">
                                {'Import products > From CSV file'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <InstructionModal showModal={showInstruction} toggleModal={toggleModal} />
        </>
    );
};

export default HowItWorks;
