// core components
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

// custom components
import App from './App';

// utility
import { store } from './redux/store/index';
import reportWebVitals from './reportWebVitals';

// css
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
