/* eslint-disable jsx-a11y/interactive-supports-focus */
// Core packages
import { useState } from 'react';

// Custom components
import InstructionModal from './InstructionModal';

// Utilities
import { ConvertingErrorIcon, ConvertingIcon } from '../assets/svg';
import {
    CONVERTING_DESCRIPTION,
    CONVERTING_FAILED_DESCRIPTION,
    CONVERTING_FAILED_TITLE,
    CONVERTING_TITLE,
    INVALID_CSV_API_ERROR,
    INVALID_CSV_ERROR_MESSAGE,
    UPLOADING_FAILED_DESCRIPTION,
    UPLOADING_FAILED_TITLE,
    UPLOADING_FILE_DESCRIPTION,
    UPLOADING_FILE_TITLE,
} from '../constants';
import { RequestRunningType, RequestType } from '../types';

const RequestRunning = ({ isError, progress, type, errorMessage = '' }: RequestRunningType) => {
    const [showInstruction, setShowInstruction] = useState(false);
    const toggleModal = () => setShowInstruction((prev) => !prev);

    const titleColor = isError ? 'error' : 'upload-file';
    const generateTitle = () => {
        if (!isError) {
            if (type === RequestType.CONVERSION) return CONVERTING_TITLE;
            return UPLOADING_FILE_TITLE;
        }
        if (isError) {
            if (type === RequestType.CONVERSION) return CONVERTING_FAILED_TITLE;
            return UPLOADING_FAILED_TITLE;
        }
        return '';
    };
    const generateErrorMessage = () => {
        if (type === RequestType.CONVERSION) {
            if (!errorMessage) return null;
            if (errorMessage !== INVALID_CSV_API_ERROR)
                return (
                    <div className="error font-sans text-center text-sm font-normal leading-5 py-3">
                        {errorMessage}
                    </div>
                );
            return (
                <div className="upload-file opacity-75 font-sans text-xs font-normal leading-5 text-center py-3">
                    <span>{INVALID_CSV_ERROR_MESSAGE}</span>
                    <span
                        className="cursor-pointer pl-1 text-blue-600"
                        role="button"
                        onClick={() => setShowInstruction(true)}
                    >
                        these steps{' '}
                    </span>
                    <span>{CONVERTING_FAILED_DESCRIPTION}.</span>
                </div>
            );
        }
        return '';
    };
    const generateDescription = () => {
        if (!isError) {
            if (type === RequestType.CONVERSION) return CONVERTING_DESCRIPTION;
            return UPLOADING_FILE_DESCRIPTION;
        }
        if (isError) {
            if (type === RequestType.CONVERSION) return null;
            return UPLOADING_FAILED_DESCRIPTION;
        }
        return '';
    };
    return (
        <>
            {!isError ? <ConvertingIcon /> : <ConvertingErrorIcon />}
            <div
                className={`${titleColor} font-sans text-center text-sm font-normal leading-5 pt-6`}
            >
                {generateTitle()}
            </div>
            {generateErrorMessage()}
            <div className="upload-file opacity-75 font-sans text-center text-xs font-normal leading-5">
                {generateDescription()}
            </div>
            {!isError ? (
                <div className="relative mt-6 w-full">
                    <div className="upload-bar w-full h-1.5 rounded-3xl opacity-10" />
                    <div
                        className="absolute on-drag top-0 h-1.5 rounded-3xl"
                        style={{ width: `${progress}%` }}
                    />
                    <div className="try-again font-sans text-center text-xs font-normal leading-5 mt-2">
                        {type === RequestType.CONVERSION ? 'Converting...' : 'Uploading...'}{' '}
                        {progress}%
                    </div>
                </div>
            ) : null}
            <InstructionModal showModal={showInstruction} toggleModal={toggleModal} />
        </>
    );
};

export default RequestRunning;
