// Core packages
import { useAppSelector, useAppDispatch } from '../redux/hooks';

// Utilities
import { Stage } from '../types';
import { BwpIcon } from '../assets/svg';
import { changeStage, removeFile } from '../redux/slice/appSlice';

const Download = () => {
    const dispatch = useAppDispatch();
    const { productCatalog, downloadUrl } = useAppSelector((state) => state.appSlice);

    const uploadNew = () => {
        dispatch(removeFile());
        dispatch(changeStage(Stage.SELECT));
    };

    const download = () => {
        window.open(downloadUrl, '_blank');
    };
    return (
        <div className="flex flex-col justify-center items-center w-full h-full py-10 px-6 md:px-10">
            <div className="upload-file font-sans text-sm font-normal text-center leading-5">
                Your file has been successfully converted.
            </div>
            <div className="try-again font-sans text-xs font-normal text-center leading-5">
                You can now download it below.
            </div>
            <div className="file-view flex flex-row items-center w-full px-4 py-2 rounded mt-7">
                <BwpIcon />
                <div className="flex flex-col w-full pl-4 pr-3">
                    <div className="upload-file font-sans text-sm font-normal leading-5">
                        {productCatalog}
                    </div>
                    <div className="upload-file opacity-75 font-sans text-xs font-normal leading-5">
                        {/* {`${fileSize} MB`} */}
                    </div>
                </div>
            </div>
            <div className="flex flex-row w-full my-6">
                <button
                    type="button"
                    className="upload-new w-1/2 font-sans text-sm font-medium rounded mr-2"
                    onClick={uploadNew}
                >
                    Upload new
                </button>
                <button
                    type="button"
                    className="button-choose-csv w-1/2 font-sans text-sm font-medium text-white rounded ml-2"
                    onClick={download}
                >
                    Download
                </button>
            </div>
        </div>
    );
};

export default Download;
