/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// Core Packages
import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-tailwind/react';

// Utilities
import { ModalProps } from '../types';
import { useAppSelector } from '../redux/hooks';
import { SuccessMessageIcon } from '../assets/svg';
import { useContactUsMutation } from '../redux/service/api';

const ContactUsModal = ({ showModal, toggleModal, submit, loading }: ModalProps) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const fileName = useAppSelector((state) => state.appSlice.productCatalog);

    useEffect(() => {
        if (!showModal) {
            setEmail('');
            setMessage('');
        }
    }, [showModal]);

    const validateEmail = (mail: string) => {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    };

    const isButtonDisabled = () => {
        if (!email || !message) return true;
        if (loading) return true;
        if (email && message) return !validateEmail(email);
        return false;
    };

    const submitMessage = () =>
        submit?.({
            email,
            description: message,
            uploaded_file_name: fileName || undefined,
        });

    return (
        <Dialog
            open={showModal}
            handler={toggleModal}
            className="w-full md:w-1/3 max-w-full absolute md:relative bottom-[-30px]"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <div className="relative p-8 w-full flex flex-col items-center">
                <span
                    className="absolute top-[18px] right-[18px] material-icons nav-icon text-white rounded-full p-1 border"
                    onClick={toggleModal}
                >
                    close
                </span>
                <div className="upload-file text-lg font-bold leading-6">Contact Us</div>
                <div className="w-full try-again text-center text-sm font-normal leading-6 pt-2">
                    Having trouble using our converter?
                    <br />
                    Do not hesitate and contact sixads team for support.
                </div>
                <div className="py-8 w-full">
                    <div className="w-full">
                        <div className="upload-file font-normal text-xs leading-5">
                            Email address
                        </div>
                        <input
                            className="input-sku w-full border border-solid rounded bg-white font-sans text-black text-sm font-normal h-10 px-3 py-[10px] mt-2"
                            placeholder="name@mail.com"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="w-full pt-4">
                        <div className="upload-file font-normal text-xs leading-5">Message</div>
                        <textarea
                            className="input-sku w-full border border-solid rounded bg-white font-sans text-black text-sm font-normal h-[104px] px-3 py-[10px] mt-2 resize-none"
                            placeholder="Please be as descriptive as possible..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                </div>

                <button
                    type="button"
                    className="button-choose-csv w-full font-sans text-sm font-medium text-white rounded"
                    disabled={isButtonDisabled()}
                    onClick={submitMessage}
                >
                    Send
                </button>
            </div>
        </Dialog>
    );
};

const SuccessModal = ({ showModal, toggleModal }: ModalProps) => (
    <Dialog
        open={showModal}
        handler={toggleModal}
        className="w-full md:w-1/3 max-w-full absolute md:relative bottom-[-30px]"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
    >
        <div className="relative p-8 w-full flex flex-col items-center">
            <span
                className="absolute top-[18px] right-[18px] material-icons nav-icon text-white rounded-full p-1 border"
                onClick={toggleModal}
            >
                close
            </span>
            <div className="pt-1">
                <SuccessMessageIcon />
            </div>
            <div className="upload-file text-2xl font-bold leading-8 pt-9">Message sent</div>
            <div className="w-full try-again text-center text-sm font-normal leading-6 pt-2">
                Your message have been sent successfully. <br />
                We typically respond within 24 hours.
            </div>

            <div className="pt-8 w-full flex justify-center items-center">
                <button
                    type="button"
                    className="upload-new w-full md:w-auto font-sans text-sm font-medium rounded"
                    onClick={toggleModal}
                >
                    Close
                </button>
            </div>
        </div>
    </Dialog>
);

const ContactUs = () => {
    const [showContactUs, setShowContactUs] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [triggerContactUs, { isSuccess, isError, isLoading, error }] = useContactUsMutation();

    const toggleContactUsModal = () => setShowContactUs((prev) => !prev);
    const toggleSuccessModal = () => setShowSuccess((prev) => !prev);

    useEffect(() => {
        if (isSuccess) {
            setShowContactUs(false);
            setShowSuccess(true);
        } else if (isError) {
            console.log({ error });
        }
    }, [isSuccess, isError]);

    return (
        <>
            <div
                className="w-full font-sans text-center text-white font-normal text-xs leading-5 underline pt-8 cursor-pointer pb-4"
                role="button"
                onClick={() => setShowContactUs(true)}
            >
                Contact sixads for support
            </div>

            <ContactUsModal
                showModal={showContactUs}
                toggleModal={toggleContactUsModal}
                submit={triggerContactUs}
                loading={isLoading}
            />
            <SuccessModal showModal={showSuccess} toggleModal={toggleSuccessModal} />
        </>
    );
};

export default ContactUs;
