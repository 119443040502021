/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AppState, OfferPrimeOptions, Stage, Transform } from '../../types';

const initialState: AppState = {
    file: '',
    fileName: '',
    fileSize: 0,
    shopUrl: '',
    transform: Transform.SAME,
    skuValue: '',
    stage: Stage.SELECT,
    productCatalog: '',
    downloadUrl: '',
    offerPrime: OfferPrimeOptions.NOT_SELECTED,
};

export const appSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        changeStage: (state, action) => {
            state.stage = action.payload;
        },
        addFile: (state, action) => {
            state.file = action.payload.file;
            state.fileName = action.payload.fileName;
            state.fileSize = action.payload.fileSize / 1000000;
        },
        removeFile: (state) => {
            state.file = '';
            state.fileName = '';
            state.fileSize = 0;
            state.productCatalog = '';
            state.downloadUrl = '';
        },
        addConversionInfo: (state, action) => {
            state.shopUrl = action.payload.shopUrl;
            state.transform = action.payload.transform;
            state.skuValue = action.payload.skuValue;
            state.offerPrime = action.payload.offerPrime;
            state.stage = Stage.CONVERTING;
        },
        removeConversionInfo: (state) => {
            state.shopUrl = '';
            state.transform = Transform.SAME;
            state.skuValue = '';
        },
        addProductCatalog: (state, action) => {
            state.productCatalog = action.payload;
        },
        removeProductCatalog: (state) => {
            state.productCatalog = '';
        },
        addDownloadUrl: (state, action) => {
            state.downloadUrl = action.payload;
        },
        removeDownloadUrl: (state) => {
            state.downloadUrl = '';
        },
    },
});

export const {
    changeStage,
    addFile,
    removeFile,
    addConversionInfo,
    removeConversionInfo,
    addProductCatalog,
    removeProductCatalog,
    addDownloadUrl,
    removeDownloadUrl,
} = appSlice.actions;

export default appSlice.reducer;
