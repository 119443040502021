// core components
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// utilities
import { api } from '../service/api';
import appSlice from '../slice/appSlice';

const reducers = combineReducers({
    appSlice,
    [api.reducerPath]: api.reducer,
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
