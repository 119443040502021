/* eslint-disable jsx-a11y/no-static-element-interactions */
// Core packages
import { Tooltip } from '@material-tailwind/react';
import { ChangeEvent, useState } from 'react';

// Utilities
import { CSVIcon } from '../assets/svg';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { OfferPrimeProps, SKUInputProps, Stage, Transform, OfferPrimeOptions } from '../types';
import { addConversionInfo, changeStage, removeFile } from '../redux/slice/appSlice';

const InputSKU = ({
    transform,
    value,
    onChange,
    title,
    showInput = false,
    skuValue = '',
    onChangeValue,
    placeholder = '',
    tooltip = '',
}: SKUInputProps) => {
    const [touched, setTouched] = useState(false);

    const isValid = (input: string) => !input.includes(' ');

    return (
        <div className="w-full mt-2">
            <div className="flex flex-row">
                <input
                    type="radio"
                    value={value}
                    checked={transform === value}
                    onChange={onChange}
                />
                <div className="sku-title inline font-sans text-xs font-normal leading-5 pl-2">
                    {title}{' '}
                    <Tooltip
                        content={tooltip}
                        className="px-2 bg-gray-500 pb-1"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <span className="absolute material-icons ml-1 text-base leading-5 text-gray-500 cursor-pointer">
                            help_outline
                        </span>
                    </Tooltip>
                </div>
            </div>
            {transform === value && showInput && (
                <>
                    <input
                        className={`${
                            !isValid(skuValue) && touched ? 'error' : 'input-sku'
                        } w-full border border-solid rounded bg-white font-sans text-black text-sm font-normal h-10 px-2 mt-2`}
                        value={skuValue}
                        onBlur={() => setTouched(true)}
                        onChange={onChangeValue}
                        placeholder={placeholder}
                    />
                    {!isValid(skuValue) && touched ? (
                        <div className="error font-sans text-xs font-normal leading-5 mt-1 ml-2">
                            {`Incorrect ${value}. Please try again`}
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

const OfferPrime = ({ value, origin, onChange, title, tooltip = '' }: OfferPrimeProps) => (
    <div className="w-full mt-2 flex flex-row">
        <input type="radio" checked={value === origin} onChange={onChange} value={value} />
        <div className="sku-title inline font-sans text-xs font-normal leading-5 pl-2">
            {title}{' '}
            <Tooltip
                content={tooltip}
                className="px-2 bg-gray-500 pb-1"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <span className="absolute material-icons ml-1 text-base leading-5 text-gray-500 cursor-pointer">
                    help_outline
                </span>
            </Tooltip>
        </div>
    </div>
);

const ConvertFile = () => {
    const [shopUrl, setShopUrl] = useState('');
    const [transform, setTransform] = useState(Transform.SAME);
    const [skuValue, setSkuValue] = useState('');
    const [offerPrime, setOfferPrime] = useState(OfferPrimeOptions.NOT_SELECTED);
    const [touched, setTouched] = useState(false);
    const dispatch = useAppDispatch();
    const { fileName, fileSize } = useAppSelector((state) => state.appSlice);

    const isInvalidUrl = (urlString: string) => {
        if (urlString.substring(0, 7) === 'http://')
            return 'Invalid protocol. Please try with https://';

        const domainValidationResult = urlString.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
        );
        const isValidDomain = domainValidationResult !== null;

        if (!isValidDomain) return 'Incorrect URL. Please try again';

        return false;
    };
    const isInvalidShopUrl = isInvalidUrl(shopUrl);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSkuValue('');
        setTransform(event.target.value as Transform);
    };

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        setSkuValue(event.target.value);
    };

    const onChangeOfferPrime = (event: ChangeEvent<HTMLInputElement>) => {
        setOfferPrime(event.target.value as OfferPrimeOptions);
    };

    const startConversion = () => {
        dispatch(addConversionInfo({ shopUrl, transform, skuValue, offerPrime }));
    };

    const cancelFile = () => {
        dispatch(removeFile());
        dispatch(changeStage(Stage.SELECT));
    };

    const isButtonDisabled = (): boolean => {
        if (offerPrime === OfferPrimeOptions.NOT_SELECTED) return true;
        if (!shopUrl) return true;
        if (isInvalidShopUrl) return true;
        if (
            (transform === Transform.PREFIX && !skuValue.length) ||
            (transform === Transform.SUFFIX && !skuValue.length) ||
            skuValue.includes(' ')
        )
            return true;
        return false;
    };

    const inputClass = `${isInvalidShopUrl && touched ? 'error' : 'input-sku'} 
        w-full border border-solid rounded bg-white font-sans text-black text-sm font-normal h-10 px-2 mt-2`;

    return (
        <div className="relative flex flex-col justify-center rounded w-full h-full my-8 py-2 px-6">
            <div className="file-view relative flex flex-row items-center w-full px-4 py-3 rounded mb-6">
                <CSVIcon />
                <div className="flex flex-col w-full pl-4 pr-3">
                    <div className="upload-file font-sans text-sm font-normal leading-5">
                        {fileName}
                    </div>
                    <div className="upload-file opacity-75 font-sans text-xs font-normal leading-5">
                        {`${fileSize} MB`}
                    </div>
                </div>
                <div
                    className="absolute cancel-file rounded-full cursor-pointer"
                    onClick={cancelFile}
                >
                    <span className="absolute material-icons cancel-file text-sm rounded-full py-0.5 px-1 border">
                        close
                    </span>
                </div>
            </div>

            <div className="w-full">
                <div className="sku-title inline font-sans text-xs font-normal leading-5">
                    Enter your store URL
                    <Tooltip
                        content="This is the landing page URL for your store starting with www."
                        className="px-2 bg-gray-500 pb-1"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <span className="absolute material-icons ml-1 text-base text-gray-500 cursor-pointer">
                            help_outline
                        </span>
                    </Tooltip>
                </div>
                <input
                    className={inputClass}
                    type="url"
                    value={shopUrl}
                    onChange={(e) => {
                        setShopUrl(e.target.value);
                    }}
                    onBlur={() => setTouched(true)}
                    placeholder="e.g. www.myshops.com"
                />
                {isInvalidShopUrl && touched ? (
                    <div className="error font-sans text-xs font-normal leading-5 mt-1 ml-2">
                        {isInvalidShopUrl}
                    </div>
                ) : null}
            </div>

            <hr className="my-6" />

            <div className="sku-title inline font-sans text-xs font-normal leading-5 pb-3">
                Buy with Prime requires Amazon SKU for your products. Choose from below option to
                add Amazon SKU to the file
            </div>

            <InputSKU
                transform={transform}
                value={Transform.SAME}
                onChange={onChange}
                title="Amazon SKU is the same as Shopify SKU"
                tooltip="Choosing this option will result in your Shopify SKU being copied as your Amazon SKU."
            />

            <InputSKU
                transform={transform}
                value={Transform.PREFIX}
                onChange={onChange}
                title="Add prefix to Shopify SKU"
                showInput
                skuValue={skuValue}
                onChangeValue={onChangeValue}
                placeholder="e.g. PrefixShopifySKU"
                tooltip="Choose this option to add a prefix (mystore) to your Shopify SKU (SKU123) and make this your Amazon SKU (mystoreSKU123)"
            />

            <InputSKU
                transform={transform}
                value={Transform.SUFFIX}
                onChange={onChange}
                title="Add suffix to Shopify SKU"
                showInput
                skuValue={skuValue}
                onChangeValue={onChangeValue}
                placeholder="e.g. ShopifySKUSuffix"
                tooltip="Choose this option to add a suffix (mystore) to your Shopify SKU (SKU123) and make this your Amazon SKU (SKU123mystore)"
            />

            <InputSKU
                transform={transform}
                value={Transform.MANUAL}
                onChange={onChange}
                title="Will enter Amazon SKU manually in CSV"
                showInput={false}
                skuValue={skuValue}
                onChangeValue={onChangeValue}
                placeholder="i.e. AMAZON_SKU"
                tooltip="Choose this option to add SKUs manually in the file after conversion"
            />

            <hr className="my-6" />

            <div className="sku-title inline font-sans text-xs font-normal leading-5 pb-3">
                Buy with Prime uses the “Offer Prime” field for you to select which products will
                offer Buy with Prime when conditions are met. Choose from the following options to
                indicate if you want to offer Prime on all of the products in your converted file
            </div>

            <OfferPrime
                value={OfferPrimeOptions.YES}
                origin={offerPrime}
                onChange={onChangeOfferPrime}
                title="Offer Prime on all the products in the converted file"
                tooltip="Choose this option to offer Prime on all the products in the converted file"
            />

            <OfferPrime
                value={OfferPrimeOptions.NO}
                origin={offerPrime}
                onChange={onChangeOfferPrime}
                title="Don’t offer Prime on all the products in the converted file"
                tooltip="Choose this option if you don’t want to offer Prime on all the products in the converted file"
            />

            <button
                type="button"
                className="button-choose-csv w-full font-sans text-sm font-medium text-white rounded mt-6 mb-7}"
                disabled={isButtonDisabled()}
                onClick={startConversion}
            >
                Convert
            </button>
        </div>
    );
};

export default ConvertFile;
