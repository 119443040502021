// Core packages
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Utilities
import { DownloadUrlResponse, UploadUrlResponse } from '../../types';

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '',
    }),
    endpoints: (builder) => ({
        getUploadUrl: builder.query<UploadUrlResponse, void>({
            query: () => process.env.REACT_APP_UPLOAD_URL_API ?? '',
            extraOptions: { maxRetries: 1 },
        }),
        putCsvFile: builder.mutation<void, any>({
            query: (payload) => ({
                url: payload.reqUrl,
                method: 'PUT',
                body: payload.file,
                headers: {
                    'Content-Type': 'text/csv',
                },
            }),
            extraOptions: { maxRetries: 1 },
        }),
        transformFile: builder.mutation<DownloadUrlResponse, any>({
            query: (payload) => ({
                url: process.env.REACT_APP_TRANSFORM_API ?? '',
                method: 'POST',
                body: payload,
            }),
            extraOptions: { maxRetries: 1 },
        }),
        contactUs: builder.mutation<any, any>({
            query: (payload) => ({
                url: process.env.REACT_APP_CONTACT_US_API ?? '',
                method: 'POST',
                body: payload,
            }),
            extraOptions: { maxRetries: 1 },
        }),
    }),
});

export const {
    useLazyGetUploadUrlQuery,
    usePutCsvFileMutation,
    useTransformFileMutation,
    useContactUsMutation,
} = api;
