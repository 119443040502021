/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
// Core Packages
import { useState } from 'react';
import { Dialog, DialogHeader, DialogBody } from '@material-tailwind/react';

// Utilities
import { ModalProps } from '../types';
import { sliderData } from '../constants';
import { ActiveIndicator, InactiveIndicator, NextIcon, PrevIcon } from '../assets/svg';

const InstructionModal = ({ showModal, toggleModal }: ModalProps) => {
    const [imageIndex, setImageIndex] = useState(400);
    const [carousel] = useState([
        `${process.env.PUBLIC_URL}/image/image1.png`,
        `${process.env.PUBLIC_URL}/image/image2.png`,
        `${process.env.PUBLIC_URL}/image/image3.png`,
        `${process.env.PUBLIC_URL}/image/image4.png`,
    ]);

    const LeftNavigation = () => (
        <a
            className="flex flex-col h-full items-center justify-center"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
            onClick={() => {
                setImageIndex((prev) => prev - 1);
            }}
        >
            <span className="nav-icon py-2 px-2.5 rounded-full" aria-hidden="true">
                <PrevIcon />
            </span>
            <span className="sr-only">Previous</span>
        </a>
    );

    const RightNavigation = () => (
        <a
            className="flex flex-col h-full items-center justify-center"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
            onClick={() => {
                setImageIndex((prev) => prev + 1);
            }}
        >
            <span className="nav-icon py-2 px-2.5 rounded-full" aria-hidden="true">
                <NextIcon />
            </span>
            <span className="sr-only">Next</span>
        </a>
    );

    return (
        <Dialog
            open={showModal}
            handler={toggleModal}
            className="w-full md:w-1/3 max-w-full absolute md:relative bottom-[-30px]"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <DialogHeader
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <div className="flex flex-row w-full justify-end">
                    <span
                        className="material-icons nav-icon text-white rounded-full p-1 border"
                        onClick={toggleModal}
                    >
                        close
                    </span>
                </div>
            </DialogHeader>
            <DialogBody
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <div className="relative w-full flex flex-col justify-center items-center pb-6 sm:pb-10 md:pb-20">
                    <div className="flex flex-row w-full sm:w-4/5 justify-between items-center">
                        <div className="h-full px-2 hidden sm:flex">
                            <LeftNavigation />
                        </div>
                        <div
                            id="carouselExampleIndicators"
                            className="carousel slide flex flex-col w-full h-full items-center justify-center md:px-6 relative"
                            data-ride="carousel"
                            data-bs-interval="false"
                            data-interval="false"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img
                                        className="d-block w-100"
                                        src={carousel[imageIndex % 4]}
                                        alt="First slide"
                                    />
                                </div>
                            </div>
                            <div className="h-full px-2 absolute sm:hidden left-0 small-screen-navigator-background">
                                <LeftNavigation />
                            </div>
                            <div className="h-full px-2 absolute sm:hidden right-0 small-screen-navigator-background">
                                <RightNavigation />
                            </div>
                        </div>
                        <div className="h-full px-2 hidden sm:flex">
                            <RightNavigation />
                        </div>
                    </div>
                    <div className="w-full flex flex-row px-10 pt-9 pb-6 justify-center items-center">
                        {sliderData.map((obj) => {
                            if (obj.id === imageIndex % 4) {
                                return (
                                    <div className="px-1" key={obj.id}>
                                        <ActiveIndicator />
                                    </div>
                                );
                            }
                            return (
                                <div className="px-1" key={obj.id}>
                                    <InactiveIndicator />
                                </div>
                            );
                        })}
                    </div>
                    {sliderData.map((obj) => {
                        if (obj.id === imageIndex % 4) {
                            return (
                                <div
                                    className="flex flex-col w-full sm:w-4/5 p-0 sm-20"
                                    key={obj.id}
                                >
                                    <div className="upload-file font-sans text-lg font-bold text-center pt-3.5">
                                        {obj.title}
                                    </div>
                                    <div className="modal-text font-sans text-sm font-normal text-center pt-2">
                                        {obj.description}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </DialogBody>
        </Dialog>
    );
};
export default InstructionModal;
